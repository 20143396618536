import person_one from "../assets/person-1.png";
import person_two from "../assets/person-2.png";
import person_three from "../assets/person-3.png";
import person_four from "../assets/person-4.png";

import person_one_mobile from "../assets/person_1_mobile.png";
import person_two_mobile from "../assets/person_2_mobile.png";
import person_three_mobile from "../assets/person_3_mobile.png";
import person_four_mobile from "../assets/person_4_mobile.png";

export const images = [person_one, person_two, person_three, person_four];

export const mobile_images = [person_one_mobile, person_two_mobile, person_three_mobile, person_four_mobile];
